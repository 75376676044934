// src/util/sdkInitialization.js

// 1) Use CommonJS require for older 1.x versions that may not expose a clean ES default import
const sharetribeSdk = require('sharetribe-flex-sdk');

// 2) Log just to confirm we have an object:
console.log('sharetribeSdk =>', sharetribeSdk);

// 3) Create the instance. We'll use memoryStore for local dev to avoid any
//    "Cannot read properties of undefined (reading 'clientId')" from cookie logic:
export const sdk = sharetribeSdk.createInstance({
  clientId: '40a83c55-f314-4527-9582-67bab7ea6ade', // your actual Marketplace API client ID
  baseUrl: 'https://flex-api.sandbox.sharetribe.com',

  // If you want cookies in production, you can do:
  // tokenStore: sharetribeSdk.tokenStore.browserCookieStore({ secure: false, domain: 'localhost' }),
  // but if that triggers an error locally, just use memoryStore:
  tokenStore: sharetribeSdk.tokenStore.memoryStore(),
});
