// src/store.js

import { legacy_createStore as createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import createReducer from './reducers';
import * as analytics from './analytics/analytics';
import appSettings from './config/settings';

// Import the SDK from sdkInitialization
import { sdk as defaultSdk } from './util/sdkInitialization';

export default function configureStore(
  initialState = {},
  customSdk = null,
  analyticsHandlers = []
) {
  const usedSdk = customSdk || defaultSdk;

  const middlewares = [
    thunk.withExtraArgument(usedSdk),
    analytics.createMiddleware(analyticsHandlers),
  ];

  const composeEnhancers =
    appSettings.dev &&
    typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      : compose;

  const enhancer = composeEnhancers(applyMiddleware(...middlewares));
  const store = createStore(createReducer(), initialState, enhancer);

  return store;
}
