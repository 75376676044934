// src/ducks/availabilityExceptions.duck.js

import { storableError } from '../util/errors';

// ================ Action Types ================ //
const FETCH_EXCEPTIONS_REQUEST = 'app/availabilityExceptions/FETCH_EXCEPTIONS_REQUEST';
const FETCH_EXCEPTIONS_SUCCESS = 'app/availabilityExceptions/FETCH_EXCEPTIONS_SUCCESS';
const FETCH_EXCEPTIONS_ERROR = 'app/availabilityExceptions/FETCH_EXCEPTIONS_ERROR';

const CREATE_EXCEPTION_REQUEST = 'app/availabilityExceptions/CREATE_EXCEPTION_REQUEST';
const CREATE_EXCEPTION_SUCCESS = 'app/availabilityExceptions/CREATE_EXCEPTION_SUCCESS';
const CREATE_EXCEPTION_ERROR = 'app/availabilityExceptions/CREATE_EXCEPTION_ERROR';

const DELETE_EXCEPTION_REQUEST = 'app/availabilityExceptions/DELETE_EXCEPTION_REQUEST';
const DELETE_EXCEPTION_SUCCESS = 'app/availabilityExceptions/DELETE_EXCEPTION_SUCCESS';
const DELETE_EXCEPTION_ERROR = 'app/availabilityExceptions/DELETE_EXCEPTION_ERROR';

// ================ Initial State ================ //
const initialState = {
  availabilityExceptions: [],
  fetchInProgress: false,
  fetchError: null,
  createInProgress: false,
  createError: null,
  deleteInProgress: false,
  deleteError: null,
};

// ================ Reducer ================ //
export default function availabilityExceptionsReducer(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case FETCH_EXCEPTIONS_REQUEST:
      return { ...state, fetchInProgress: true, fetchError: null };
    case FETCH_EXCEPTIONS_SUCCESS:
      return { ...state, fetchInProgress: false, availabilityExceptions: payload.exceptions };
    case FETCH_EXCEPTIONS_ERROR:
      return { ...state, fetchInProgress: false, fetchError: payload };

    case CREATE_EXCEPTION_REQUEST:
      return { ...state, createInProgress: true, createError: null };
    case CREATE_EXCEPTION_SUCCESS:
      return {
        ...state,
        createInProgress: false,
        availabilityExceptions: [...state.availabilityExceptions, payload.exception],
      };
    case CREATE_EXCEPTION_ERROR:
      return { ...state, createInProgress: false, createError: payload };

    case DELETE_EXCEPTION_REQUEST:
      return { ...state, deleteInProgress: true, deleteError: null };
    case DELETE_EXCEPTION_SUCCESS:
      return {
        ...state,
        deleteInProgress: false,
        availabilityExceptions: state.availabilityExceptions.filter(
          exc => exc.id !== payload.exceptionId
        ),
      };
    case DELETE_EXCEPTION_ERROR:
      return { ...state, deleteInProgress: false, deleteError: payload };

    default:
      return state;
  }
}

// ================ Action Creators ================ //
export const fetchExceptionsRequest = () => ({ type: FETCH_EXCEPTIONS_REQUEST });
export const fetchExceptionsSuccess = exceptions => ({
  type: FETCH_EXCEPTIONS_SUCCESS,
  payload: { exceptions },
});
export const fetchExceptionsError = e => ({
  type: FETCH_EXCEPTIONS_ERROR,
  payload: e,
  error: true,
});

export const createExceptionRequest = () => ({ type: CREATE_EXCEPTION_REQUEST });
export const createExceptionSuccess = exception => ({
  type: CREATE_EXCEPTION_SUCCESS,
  payload: { exception },
});
export const createExceptionError = e => ({
  type: CREATE_EXCEPTION_ERROR,
  payload: e,
  error: true,
});

export const deleteExceptionRequest = () => ({ type: DELETE_EXCEPTION_REQUEST });
export const deleteExceptionSuccess = exceptionId => ({
  type: DELETE_EXCEPTION_SUCCESS,
  payload: { exceptionId },
});
export const deleteExceptionError = e => ({
  type: DELETE_EXCEPTION_ERROR,
  payload: e,
  error: true,
});

// ================ Thunks ================ //

/**
 * Fetch existing availability exceptions for a given listing and date range.
 */
export const onFetchAvailabilityExceptions = ({ listingId, start, end }) => (
  dispatch,
  getState,
  sdk
) => {
  dispatch(fetchExceptionsRequest());

  return sdk.availabilityExceptions
    .query({
      listingId,
      start: new Date(start),
      end: new Date(end),
    })
    .then(response => {
      const data = response.data.data;
      dispatch(fetchExceptionsSuccess(data));
      return data;
    })
    .catch(e => {
      dispatch(fetchExceptionsError(storableError(e)));
      throw e;
    });
};

/**
 * Create (add) an availability exception for the specified listing.
 */
export const onAddAvailabilityException = ({ listingId, start, end, seats }) => (
  dispatch,
  getState,
  sdk
) => {
  dispatch(createExceptionRequest());

  // Validate payload before sending
  if (!listingId || !start || !end) {
    const error = new Error('Missing required fields: listingId, start, end');
    dispatch(createExceptionError(error));
    return Promise.reject(error);
  }

  const payload = { listingId, start: new Date(start), end: new Date(end), seats };

  return sdk.availabilityExceptions
    .create(payload, { expand: true })
    .then(response => {
      const data = response.data.data;
      dispatch(createExceptionSuccess(data));
      return data;
    })
    .catch(e => {
      dispatch(createExceptionError(storableError(e)));
      throw e;
    });
};

/**
 * Delete an availability exception by ID.
 */
export const onDeleteAvailabilityException = exceptionId => (dispatch, getState, sdk) => {
  dispatch(deleteExceptionRequest());

  return sdk.availabilityExceptions
    .delete(
      {
        id: exceptionId, // Correctly pass the id within an object
      },
      {
        expand: false,
      }
    )
    .then(() => {
      dispatch(deleteExceptionSuccess(exceptionId));
    })
    .catch(e => {
      dispatch(deleteExceptionError(storableError(e)));
      throw e;
    });
};
